.box-container {
    display: flex;
    gap: 5px;
  }
  
  /* .box {
    height: 50px;
  } */
  
  .left {
    flex-basis: 75%;
    background-color: #8bc34a;
    padding: 7px;
  }
  
  .center {
    flex-basis: 25%;
    background-color: #ff9800;
    padding: 7px;
  }
  
  .right {
    flex-basis: 75%;
    background-color: #f44336;
    padding: 7px;
  }

  .box-text {
    font-size: small;
    font-weight: bold;
    color: white;
    text-align: center;
  }

  .spinner {
    animation: spin infinite 1.5s linear;

    /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}