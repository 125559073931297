@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~leaflet/dist/leaflet.css';

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100&display=swap');

*{
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, Opera */
  }
}

.enable-scrollbar {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
  border-radius: 10%;
}
.enable-scrollbar::-webkit-scrollbar {
  display: block; 
  width: 2px; 
 
}


.enable-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;

}
.enable-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}
.enable-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}


body,
html {
  font-family: 'Poppins', sans-serif !important;
  scroll-behavior: smooth;
}

.draggable {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

.draggable-category {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem;
}

h2 {
  margin: 0;
}

.drag-handle {
  margin-top: inherit;
  border: 1px solid rgb(209, 209, 209);
  background: rgb(209, 209, 209);
  padding: 0.1rem;
  border-radius: 4px;
}

.category-container {
  width: 90%;
}

.item {
  border: 2px solid gray;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  background: lightgray;
}

.leaflet-container {
  height: 400px;
  width: 400px;
  border: 1px solid #ccc;
}

.address-dialog .leaflet-container {
  margin: 5px 20px;
  height: 300px;
  width: 300px;
  /* border: 1px solid #ccc; */
}

/* 
.leaflet-pane{
  width: 200px;
} */

.address-dialog .leaflet-map-pane {
  width: 100% !important;
}

/* 


.address-dialog .leaflet-div-icon {
  background: transparent;
  border: none;
} */
/* 
.address-dialog .leaflet-pane{
  width: 200px;
} */

/* scrollbar */
/* ::-webkit-scrollbar {
  width: 12px; 
} */

/* Define the scrollbar handle */
/* ::-webkit-scrollbar-thumb {
  background-color: #DB7D0B;
  border-radius: 6px; 
} */

